@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
.adcreator-container {
  width: 700px;
  margin-left: 'auto';
  margin-right: 'auto';
  margin-top: 10px;

  h3 {
    color: black;
    margin-bottom: 20px;
    font-weight: normal;
  }
  img {
    margin-top: 20px;
  }
  .version-p {
    margin-top: 0px;
    font-size: 16px;
    color: #808080;
  }
  .form-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: center;
    box-sizing: border-box;
    &-left {
      float: left;
      width: 50%;
      box-sizing: border-box;
      padding: 20px;
    }
    &-right {
      float: right;
      width: 50%;
      box-sizing: border-box;
      padding: 20px;
    }
  }

  .button-container {
    width: 100%;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 50px;
  }
  .checkbox-container-right {
    float: right;
    margin: 10px 0px;
    label {
      font-size: 20px;
      font-weight: lighter;
    }
    input[type='checkbox'] {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .checkbox-container-left {
    float: left;
    margin: 10px 0px;
    text-align: left;
    label {
      font-size: 20px;
      font-weight: lighter;
    }
    input[type='checkbox'] {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.error-container {
  h3 {
    color: red;
  }
}

.custom-button-disabled {
  background-color: #cccccc; /* Grey out the button */
  color: #666666; /* Dim the text color if needed */
  cursor: not-allowed; /* Show a 'no entry' cursor */
  pointer-events: none; /* Prevents click events */
}
.material-ui-select {
  margin: 10px 0px;
}
.display-none {
  display: none;
}
.documents-container {
  width: 50vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  button {
    padding: 10px;
    background-color: white;
    color: black;
    font-weight: bold;
    outline: none;
    text-decoration: none;
    border: 1px solid black;
    margin-top: 15px;
    &:hover {
      cursor: pointer;
      color: tomato;
      border: 1px solid tomato;
    }
  }
}

.add-document-button {
  float: right;
  padding: 7px;
  margin: 15px 0px;
  cursor: pointer;
  background-color: #ff2e00;
  outline: none;
  color: white;
  border: 1px solid black;
  border-radius: 2px;
  transition: all 0.6s ease;
  font-family: sans-serif;
  &:hover {
    background-color: red;
  }
}
.color {
  color: red;
}
//added !important, so it will be implicitly added
.specialInstruction-textArea {
  color: black !important;
  border: 1px solid black !important;
}
.textArea-label {
  margin: 8px !important;
}
