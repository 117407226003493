.container {
  width: 100%;
  overflow: hidden;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* 16:9 Aspect Ratio */
  span {
    color: blue;
    font-weight: normal;
    font-size: 20px;
  }
}
