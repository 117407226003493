body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.scrollable {
  border: 1px solid #000;
  color: #00008b;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  text-align: left;

  &.IframeDiv {
    margin-top: 10px;
  }
}

.App {
  text-align: center;

  &-logo {
    height: 40vmin;
    pointer-events: none;
  }

  &-header {
    background-color: #ffffff;
    color: #fff;
    justify-content: center;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    // align-items: center;
    // display: flex;
    // flex-direction: column;
  }

  &-link {
    color: #61dafb;
  }
}

.MuiContainer-root {
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;

  h4 {
    color: black;
    margin-top: 10px;
  }
}

.MuiButton-containedPrimary {
  background-color: rgb(234, 83, 35) !important;

  &:hover {
    background-color: rgb(216, 60, 10) !important;
  }
}

.flex-style-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-style-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.color-red {
  color: red;
}
