$primary: rgb(54, 54, 163);
$black: black;
$red: red;
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-bottom: 10px;
  width: 100%;
}

.form__field,
.form__field--textarea {
  font-family: inherit;
  font-weight: lighter;
  font-style: normal;
  width: 100%;
  border: 0;
  border-bottom: 1.5px solid $black;
  outline: 0;
  font-size: 18px;
  color: $black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 15px;
    cursor: text;
    top: 20px;
  }
}
.form__field--textarea {
  min-height: 60px; // Minimum height for a 3-line textarea
  resize: vertical; // Allow vertical resizing
  overflow: auto; // Ensure proper scrolling
  border: 1px grey solid;
  padding: 0.3em;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $black;
}
.form__label__error {
  position: absolute;
  top: 55px;
  margin-left: 10px;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: $red;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    color: $primary;
    font-weight: 300;
  }
  padding-bottom: 6px;
  font-weight: normal;
  border-width: 2px;
  border-image: linear-gradient(to right, $primary, $primary);
  border-image-slice: 1;
}
/* reset input */
.form__field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}
.color-red {
  color: red;
}
