.campaigns-page {
  .view-campaigns-header {
    font-size: 36px;
    font-weight: lighter;
    margin: 20px 0;
    text-align: center;
  }

  .pagination-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px;
  }
  .no-campaigns {
    text-align: center;
    margin-top: 40px;

    .new-campaign-card {
      display: inline-block;
      margin-top: 20px;
      border: 1px solid #f4a261;
      border-radius: 5px;
      padding: 20px;
      cursor: pointer;

      .plus-icon {
        font-size: 36px;
        color: #f4a261;
      }

      p {
        font-size: 16px;
        color: #f4a261;
        margin-top: 5px;
      }
    }
  }

  .campaigns-container {
    .latest-campaigns {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;

      .campaign-card,
      .new-campaign-card {
        border: 1px solid #f4a261;
        border-radius: 5px;
        padding: 20px;
        cursor: pointer;
        transition: box-shadow 0.3s ease;

        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        .campaign-image {
          width: 50%;
          border-radius: 5px;
          margin-right: 15px;
        }

        .campaign-info {
          h3 {
            font-size: 16px;
            color: #666;
            margin: 0;
          }

          p {
            font-size: 14px;
            color: #666;
            margin: 5px 0 0;
          }
        }

        .plus-icon {
          font-size: 36px;
          color: #f4a261;
        }

        p {
          font-size: 16px;
          color: #f4a261;
          margin-top: 5px;
        }
      }
    }

    .all-campaigns-table {
      margin-top: 40px;

      table {
        width: 80%; /* Make the table smaller */
        margin: 0 auto; /* Center the table */
        border-collapse: collapse;

        th,
        td {
          border-bottom: 1px solid #f4a261;
          padding: 10px;
          text-align: left;
          color: black;
          font-size: 20px;
          font-weight: lighter;
          a {
            text-decoration: none;
          }
        }

        th {
          color: black; // Example header color
          font-size: 25px;
          text-align: left;
        }

        .actions {
          color: #f4a261;
          font-weight: normal;
          font-size: 16px;
          a {
            text-decoration: none;
            color: #f4a261;
          }
          b {
            font-size: 25px;
          }
          a:hover {
            color: purple;
            cursor: pointer;
          }
          &:hover {
            color: purple;
            cursor: pointer;
          }
        }
      }
    }
  }
}
