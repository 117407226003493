.execute-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

p {
  color: black;
}

.adcreatorpage-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.execute-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffcc00;
}
.dismiss-button {
  margin: 5px;
  position: absolute; /* Position the button absolutely within its parent container */
  top: 5px; /* Align to the top of the announcement-box */
  right: 5px;
}
.announcement-area {
  position: fixed; /* Keeps the box fixed on the screen */
  top: 20px; /* Distance from the top of the viewport */
  right: 20px; /* Distance from the right of the viewport */
  width: 300px; /* Width of the announcement box */
}
.announcement-box {
  border: 2px solid black;
  margin: 3px;
  padding: 10px; /* Padding inside the box */
  background-color: #ffcc00; /* Highlight color */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
  z-index: 1000; /* Ensures the box will be on top of other elements */
  color: black;
  font-size: 13px;
  max-height: 500px;
  overflow: auto;
}
.announcement-pre {
  padding: 5px; // Move content closer to border.
  white-space: pre; // Preserves whitespace and line breaks
  font-family: monospace; // Typically, <pre> uses monospaced fonts
  display: block; // Makes sure the div is block-level like <pre>
  text-align: left; // Ensures text is always left-aligned
  font-size: 12px; // Ensures text is always the same size
  div {
    margin-top: -20px;
  }
  p {
    display: inline-block;
  }
  a {
    color: blue;
    font-weight: bold;
    float: right;
    padding-top: 20px;
    padding-right: 20px;
  }
  // Add any other styles you want to inherit from <pre>
}
.clear-box {
  position: fixed; /* Keeps the box fixed on the screen */
  bottom: 40px; /* Distance from the top of the viewport */
  right: 20px; /* Distance from the right of the viewport */
  z-index: 1000; /* Ensures the box will be on top of other elements */
}

.top-left {
  position: absolute;
  margin-top: 60px;
  font-size: 13px;
  top: 20px; /* Distance from the top of the viewport */
  left: 20px; /* Distance from the right of the viewport */
  z-index: 1000; /* Ensures the box will be on top of other elements */
}
.template-div {
  margin-bottom: 60px;
}
.template-heading-bold {
  color: black;
  font-weight: bold;
  display: inline;
}

.template-heading-normal {
  color: black;
  font-weight: normal;
  display: inline;
}

///error box
.error-content {
  max-width: 320px;
  min-width: 310px;
  position: fixed; /* Keeps the box fixed on the screen */
  top: 20px; /* Distance from the top of the viewport */
  left: 100px;
  padding: 10px; /* Padding inside the box */
  z-index: 1200; /* Ensures the box will be on top of other elements */
  color: black;
  font-size: 16px;
  word-wrap: break-word;
}
.error-head {
  background-color: #ff1205;
  border-radius: 10px 10px 0 0;
  color: white;
  text-align: center;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid black;
  border-bottom: 0px;
  word-wrap: break-word;
}
.remove-error {
  float: right;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 20%;
}
.error-body {
  background-color: #d4d4d4;
  min-height: 70px;
  word-wrap: break-word;
  height: auto;
  padding: 7px;
  font-size: 14px;
  border-radius: 0 0 10px 10px;
  border: 1px solid black;
  border-top: 0px;
}
.refresh-button {
  padding: 7px;
  margin-left: 10px;
  background-color: #ff5722;
  outline: none;
  border: 2px solid #ff5722;
  border-radius: 5px;
  color: whitesmoke;
  font-size: 15px;
  &:hover {
    background-color: #ee6a42;
    outline: none;
    border: 2px solid #ee6a42;
    cursor: pointer;
  }
}

.download-zip-button {
  padding: 7px;
  background-color: rgb(142, 34, 142);
  outline: none;
  border: 2px solid rgb(142, 34, 142);
  border-radius: 5px;
  margin-left: 10px;
  color: whitesmoke;
  font-size: 15px;
  &:hover {
    background-color: purple;
    outline: none;
    border: 2px solid purple;
    cursor: pointer;
  }
}
.button-container {
  margin: 50px 0px;
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.download-combined-zipFile {
  background-color: rgb(142, 34, 142);
  outline: none;
  border: 2px solid rgb(142, 34, 142);
  &:hover {
    background-color: purple;
    outline: none;
    border: 2px solid purple;
    cursor: pointer;
  }
}

.zip-container {
  margin: 50px 0px;
}
.header-actions-container {
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.custom-button-disabled {
  background-color: #cccccc; /* Grey out the button */
  color: #666666; /* Dim the text color if needed */
  cursor: not-allowed; /* Show a 'no entry' cursor */
  pointer-events: none; /* Prevents click events */
}
.download-zip-button-disabled {
  padding: 7px;
  outline: none;
  border: 2px solid rgb(142, 34, 142);
  border-radius: 5px;
  margin-left: 10px;
  font-size: 15px;
  background-color: #5f3d60; /* Grey out the button */
  color: #aaa6a6; /* Dim the text color if needed */
  cursor: not-allowed; /* Show a 'no entry' cursor */
  pointer-events: none; /* Prevents click events */
}
