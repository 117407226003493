@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.button-list {
  float: right;
  list-style-type: none;
}

.header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.header {
  height: 70px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  border-bottom: 1px solid black;
  margin: 10px;

  .logo-container {
    img {
      width: 60%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all 1s ease;
    .option {
      padding: 10px 15px;
      text-decoration: none;
      font-size: 18px;
      font-weight: normal;
      color: black;
      font-family: 'Noto Sans', sans-serif;
      &:hover {
        color: #ff5725;
      }
    }
  }
}
