.campaign-grid-container {
  color: black;
  font-family: Arial, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
table {
  max-width: 50%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 10px 15px;
  margin-bottom: 50px;
  overflow-x: auto;
}
thead {
  margin-bottom: 100px;
}
.grid-headers {
  padding: 8px;
  font-size: 16px;
  word-break: keep-all;
  width: 1px;
}
.tbody-tr-grid {
  margin: 10px 0px;
}

.tbody-td-grid {
  text-align: left;
  margin: 20px 0px;
  font-size: 17px;
  vertical-align: bottom; /* Align text to the bottom */
}

.ad-button {
  background-color: rgb(67, 67, 205);
  color: black;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

/* tooltipTest */

/*tooltip Box*/
.con-tooltip {
  position: relative;
  background: #c3e8f9;
  color: black;
  width: 110px;
  height: 40px;
  border-radius: 3px;
  margin: 0px 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.con-tooltip.top.light-grey {
  background-color: #d3d3d3; /* or any light grey color you prefer */
}

/*tooltip */
.tooltip {
  visibility: hidden;
  z-index: 1;
  opacity: 0.4;
  width: auto;
  max-width: 300px;
  max-height: 350px;
  height: auto;
  padding: 0px 20px;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: whitesmoke;
  position: absolute;
  bottom: 100%;
  left: -25%;
  border-radius: 9px;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
  text-align: left;
  overflow: auto;
}

/* position: absolute;
  bottom: 0%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: auto;
  height: auto;
  max-width: auto;
  border-radius: 3px;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: whitesmoke;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 13px;
  line-height: 2; */

/* tooltip  after*/
.tooltip::after {
  content: ' ';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12.5px 0 12.5px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  left: 40%;
}

.con-tooltip:hover .tooltip {
  visibility: visible;
  transform: translateY(-10px);
  opacity: 1;
  transition: 0.3s linear;
}
/*hover ToolTip*/

.top:hover {
  transform: translateY(-6px);
}

select {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  cursor: context-menu;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  outline: none;
  border: none;
  background-color: transparent;
  margin-top: -100px;
  position: relative;
  option {
    text-align: center;
    background-color: whitesmoke;
    color: black;
  }
}
span {
  position: absolute;
}

//////////////
/// //////////////
/// /////////////
/// ////////////
/// /////////////
/// ///////////

.styled-select {
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;

  overflow: hidden;
  position: relative;
}
.styled-select,
.styled-select select {
  width: 240px;
}
select:focus {
  outline: none;
}
.styled-select select {
  height: 34px;
  padding: 5px 0 5px 5px;
  background: transparent;
  border: none;

  /*hide default down arrow in webkit */
  -webkit-appearance: none;
}

@-moz-document url-prefix() {
  .styled-select select {
    width: 110%;
  }
}

.fa-sort-desc {
  position: absolute;
  top: 0;
  right: 12px;
  font-size: 24px;
}

select::-ms-expand {
  display: none;
} /* hide default down arrow in IE10*/

/* hack to fall back in opera */
_:-o-prefocus,
.selector {
  .styled-select {
    background: none;
  }
}

.bordered {
  border: 2px solid rgb(57, 253, 57);
}
.bordered-url {
  border: 2px solid rgb(231, 53, 231);
}
.list-fields-container {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  flex-direction: column;
}
.text-area {
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid black;
}
.edit-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  .checkButton {
    width: 20px;
    height: 20px;
  }
}
.modalPopUpStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: #333;
  border: 2px solid #000;
  box-shadow: 24;
}

.popUpStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #ffff; // Ensure background.paper is a variable or a valid color value
  border: 2px solid #000;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.5); // Adjust the rgba values based on your desired shadow color and opacity
  padding: 16px; // Adjust the padding as needed
}

//con-tool-tip 2
//
.con-tooltip2 {
  position: relative;
  color: black;
  width: 70px;
  height: 30px;
  border-radius: 3px;
  margin: 10px 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.con-tooltip2::after {
  content: 'Edit Field';
  color: rgb(0, 106, 255);
  font-size: 15px;
}

/*tooltip */
.tooltip2 {
  visibility: hidden;
  z-index: 1;
  opacity: 0.4;
  width: 350px;
  height: 400px;
  padding: 50px 20px;
  background-color: #ffff;
  color: black;
  position: absolute;
  bottom: 100%;
  left: -250%;
  border-radius: 5px;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
  overflow: auto;
  pre {
    margin: 0;
  }
}

/* tooltip  after*/
.tooltip2::after {
  content: '';
  width: 0;
  height: 0;

  border-style: solid;
  border-width: 12px 12.5px 0 12.5px;
  border-color: red transparent transparent transparent;

  position: absolute;
  left: 40%;
  display: none;
}

.con-tooltip2:hover .tooltip2 {
  visibility: visible;
  transform: translateY(-10px);
  opacity: 1;
  transition: 0.3s linear;
}
/*hover ToolTip*/

.top2:hover {
  transform: translateY(-6px);
}
.sd_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
