.adModifyer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .textArea-label {
    margin: 8px !important;
  }
  .modifyAd-textArea {
    color: black !important;
    width: 60% !important;
    border: 1px solid black !important;
  }
  .action-buttons {
    margin: 20px;
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .download-zip-button {
      padding: 7px;
      background-color: rgb(142, 34, 142);
      outline: none;
      border: 2px solid rgb(142, 34, 142);
      border-radius: 5px;
      margin-left: 10px;
      color: whitesmoke;
      font-size: 15px;
      &:hover {
        background-color: purple;
        outline: none;
        border: 2px solid purple;
        cursor: pointer;
      }
    }

    .cancel-modify-button {
      padding: 7px;
      background-color: tomato;
      outline: none;
      border: 2px solid tomato;
      border-radius: 5px;
      margin-left: 10px;
      color: white;
      font-size: 15px;
      &:hover {
        background-color: red;
        outline: none;
        border: 2px solid red;
        cursor: pointer;
      }
    }
  }
}
.custom-button-disabled {
  background-color: #cccccc !important ; /* Grey out the button */
  color: #666666 !important; /* Dim the text color if needed */
  cursor: not-allowed !important; /* Show a 'no entry' cursor */
  pointer-events: none !important; /* Prevents click events */
  border: none !important;
}
